.gray-text {
  color: #646464;
}

.total-count-card {
  height: 13em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.total-count-icon {
  padding: 0.5em;
  margin-left: 0.85em;
  margin-bottom: 1em;
}

.total-count-icon > span {
  font-size: 2.5rem;
}

.total-sales-by-category-card .ant-typography {
  text-align: center;
}

.pointer-cursor {
  cursor: pointer;
}
