.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.add_customer_modal {
  width: 60% !important;
}
.ant-modal-content .ant-modal-body .inner-head {
  font-size: 15px;
  color: #1a3353;
  border-bottom: 1px solid #0000001f;
  margin: 20px 0px;
  padding-bottom: 5px;
}
.ant-modal-content .ant-modal-body .inner-border {
  border: 1px solid black;
  padding: 30px 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
  position: relative;
}
.ant-modal-content .ant-modal-body .inner-border h2 {
  position: absolute;
  top: -17px;
  background: #e9e9e9;
  font-size: 15px;
  padding: 5px 20px;
  border-radius: 10px;
}
